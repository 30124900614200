canvas {
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;
  position: static;
}

@media (width <= 768px) {
  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
/*# sourceMappingURL=index.9548d9e0.css.map */
